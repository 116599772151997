<template>
  <b-card
    no-body
    footer-tag="footer"
    class="card-portfolio mb-0"
  >
    <b-card-body class="p-0">
      <b-tabs v-model="tabIndex">
        <div class="action-bar justify-content-between">
          <div />
          <div>
            <b-button
              v-if="tabIndex === 0"
              class="ml-1"
              variant="primary"
            >
              <feather-icon
                icon="UploadIcon"
                size="16"
              />&nbsp;
              <span>Export</span>
            </b-button>
            <b-button
              v-if="tabIndex === 1"
              v-b-modal.modal-edit-column
              class="ml-1"
              variant="primary"
            >
              <feather-icon
                icon="EyeIcon"
                size="16"
              />&nbsp;
              <span>Edit Columns</span>
            </b-button>
            <b-button
              v-if="(tabIndex === 1)"
              v-b-modal.unit-reporting-update-modal
              class="ml-1"
              variant="primary"
            >
              <feather-icon
                icon="RotateCwIcon"
                size="16"
              />&nbsp;
              <span>Update</span>
            </b-button>
            <b-button
              v-if="tabIndex === 1"
              class="ml-1"
              variant="primary"
            >
              <feather-icon
                icon="UploadIcon"
                size="16"
              />&nbsp;
              <span>Export</span>
            </b-button>
            <b-button
              v-if="tabIndex === 2"
              class="ml-1"
              variant="primary"
            >
              <feather-icon
                icon="UploadIcon"
                size="16"
              />&nbsp;
              <span>Export</span>
            </b-button>
            <b-button
              v-if="tabIndex === 2"
              class="ml-1"
              variant="primary"
            >
              <feather-icon
                icon="CommandIcon"
                size="16"
              />&nbsp;
              Manage
            </b-button>
            <!-- <b-button class="ml-1" variant="primary">
              <feather-icon icon="ZapIcon" size="16" />&nbsp;
              <span>Configure</span>
            </b-button> -->
          </div>
          <!-- <b-button-group>
            <b-button variant="outline-primary" :class="{'active': isChartView}" @click="handleChangeViewMode(true)">
              <b-icon icon="bar-chart-line" />
            </b-button>
            <b-button variant="outline-primary" :class="{'active': !isChartView}" @click="handleChangeViewMode(false)">
              <b-icon icon="table" />
            </b-button>
          </b-button-group>
          <b-button-group>
            <b-button variant="outline-primary">
              <b-icon icon="pencil" />
            </b-button>
            <b-button variant="outline-primary">
              <feather-icon icon="DollarSignIcon" />
            </b-button>
          </b-button-group> -->
        </div>
        <!-- <div v-if="tabIndex === 2" class="action-bar justify-content-between">
          <b-button variant="flat-primary" @click="handleUpdate">
            <feather-icon icon="RotateCwIcon" />
            Update
          </b-button>
          <b-button-group>
            <b-button variant="outline-primary" :class="{'active': !isChartView}" @click="handleChangeViewMode(false)">
              <b-icon icon="bar-chart-line" />
            </b-button>
            <b-button variant="outline-primary" :class="{'active': isChartView}" @click="handleChangeViewMode(true)">
              <b-icon icon="table" />
            </b-button>
          </b-button-group>
          <div class="d-flex action-group">
            <div class="d-flex">
              <div class="rounded"
                style="background-color:#8b3b4e;height:15px;width:15px;margin-top:3px;margin-right: 3px;" />
              <div>ENGAGED</div>
            </div>
            <div class="d-flex">
              <div class="ml-2 rounded"
                style="background-color:#448739;height:15px;width:15px;margin-top:3px;margin-right: 3px;" />
              <div>QUOTE</div>
            </div>
            <div class="d-flex">
              <div class="ml-2 rounded"
                style="background-color:#0a5666;height:15px;width:15px;margin-top:3px;margin-right: 3px;" />
              <div>ESTIMATED</div>
            </div>
          </div>
        </div> -->
        <b-tab
          id="demand"
          title="Demand"
          event-key="demand"
          :class="{ 'has-default-card-bg': !isChartView }"
          @click="onClickCPSelectBtn('demand')"
        >
          <Demand
            :data="c_demand_data"
            :fields="fields"
          />
        </b-tab>
        <b-tab
          id="reporting"
          title="Reporting"
          event-key="reporting-cost"
          @click="onClickCPSelectBtn(reportingState === 'cost' ? 'reporting-cost' : 'reporting-plan')"
        >
          <Reporting :reporting-state="reportingState" />
        </b-tab>
        <b-tab
          id="control-table"
          title="Control"
          class="no-action-bar"
          event-key="control-table"
          @click="onClickCPSelectBtn('control-table')"
        >
          <Control :is-chart-view="isChartView" />
        </b-tab>
        <template #tabs-end>
          <div class="d-flex ml-auto justify-content-end align-items-center pt-1 pb-1 actions">
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
                style="margin-right:3px"
              />
              <div style="white-space:nowrap">
                Period
              </div>
              <div class="ml-1">
                <b-form-input
                  id="popover-manual-1"
                  v-model="selectedMonth"
                  style="width:160px"
                  readonly
                />
                <b-popover
                  ref="popover"
                  placement="bottomleft"
                  target="popover-manual-1"
                  :show.sync="popoverShow"
                >
                  <div
                    v-click-outside="onClose"
                    style="display:flex;"
                  >
                    <div class="mr-1">
                      <month-picker
                        no-default
                        style="width:300px"
                        variant="dark"
                        @input="onRangeFromChange"
                      />
                    </div>
                    <div>
                      <month-picker
                        no-default
                        style="width:300px"
                        variant="dark"
                        @input="onRangeToChange"
                      />
                    </div>
                  </div>
                </b-popover>
              </div>
            </div>
            <b-button-group
              v-if="(tabIndex === 2)"
              class="ml-1"
            >
              <b-button
                variant="outline-primary"
                :style="`background-color:${isChartView ? '#473ca3' : '#0000'}`"
                @click="handleChangeViewMode(true)"
              >
                Chart
              </b-button>
              <b-button
                variant="outline-primary"
                :style="`background-color:${!isChartView ? '#473ca3' : '#0000'}`"
                @click="handleChangeViewMode(false)"
              >
                Table
              </b-button>
            </b-button-group>
            <b-button-group
              v-if="tabIndex === 1"
              class="ml-1"
            >
              <b-button
                variant="outline-primary"
                :style="`background-color:${reportingState === 'cost' ? '#473ca3' : '#0000'}`"
                @click="onClickCPSelectBtn('reporting-cost', 'cost')"
              >
                Cost
              </b-button>
              <b-button
                variant="outline-primary"
                :style="`background-color:${reportingState === 'plan' ? '#473ca3' : '#0000'}`"
                @click="onClickCPSelectBtn('reporting-plan', 'plan')"
              >
                Plan
              </b-button>
            </b-button-group>
            <!-- <b-button v-if="tabIndex === 0" v-b-modal.modal-optimize class="ml-1" variant="primary">
              <feather-icon icon="ZapIcon" size="16" />
              <span>Optimize</span>
            </b-button> -->
          </div>
        </template>
      </b-tabs>
    </b-card-body>
    <template #footer>
      <b-button
        variant="primary"
        @click="toggleCreateNewUnitDrawer"
      >
        <feather-icon icon="PlusIcon" />
      </b-button>
    </template>
    <create-modal />
    <edit-columns-modal
      :checked-data="activeColumns"
      @columnChange="columnChange"
    />
    <optimize-modal />
    <Drawer
      align="right"
      :closeable="false"
      :mask-closable="true"
      @close="toggleCreateNewUnitDrawer"
    >
      <div v-if="openCreateNewUnitDrawer">
        <CreateNewUnitDrawer />
      </div>
    </Drawer>
  </b-card>
</template>

<script>
import {
  BButton, BCard, BCardBody, BTabs, BTab, BFormInput, BPopover, BButtonGroup
} from 'bootstrap-vue'
import Drawer from "vue-simple-drawer"
import moment from 'moment'
import ClickOutside from 'vue-click-outside'
import { MonthPicker } from 'vue-month-picker'
import { isEmpty } from "@/views/utils"
import Demand from './components/Demand.vue'
import Reporting from './components/Reporting.vue'
import Control from './components/Control.vue'
import CreateModal from './modals/CreateModal.vue'
import EditColumnsModal from './modals/EditColumnsModal.vue'
import OptimizeModal from './modals/OptimizeModal.vue'
import CreateNewUnitDrawer from './modals/CreateNewUnitDrawer.vue'

export default {
  components: {
    BButton,
    BButtonGroup,
    BCard,
    BCardBody,
    BTabs,
    BTab,
    Demand,
    Reporting,
    Control,
    CreateModal,
    EditColumnsModal,
    OptimizeModal,
    MonthPicker,
    BFormInput,
    BPopover,
    CreateNewUnitDrawer,
    Drawer,
  },
  directives: {
    ClickOutside
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeTab: 'demand',
      reportingState: 'cost',
      rangeDate: [],
      activeColumns: ['priority', 'currentPhase', 'next_gate'],
      defaultFields: [{ key: 'show_details', thStyle: 'opacity: 0; width: 30%;' }, { key: 'actions', thStyle: 'opacity: 0; width: 17%;' }],
      fields: ['priority', 'budget', 'deadline'],
      fieldForDemand: ['BUDGET of team', 'BUDGET engaged', 'Budget Real Engaged'],
      tabIndex: 0,
      isChartView: false,
      popoverShow: false,
      selectedMonth: `${new Date().getMonth() + 1} / ${new Date().getFullYear()} - ${new Date().getMonth() + 1} / ${new Date().getFullYear()}`,
      rangeArray: []
    }
  },
  computed: {
    openCreateNewUnitDrawer() {
      return this.$store.state.globalState.openCreateNewUnitDrawer
    },
    c_demand_data() {
      return this.$store.state.teamState.teamDemandData
    },
    c_reporting_data() {
      return this.$store.state.teamState.teamReportingData
    },
    c_control_data() {
      return this.$store.state.teamState.teamControlData
    }
  },
  mounted() {
    this.triggerTabChange('control-table')
    this.fields = [...this.defaultFields]
    this.activeColumns.forEach((column, idx) => {
      this.fields.splice(idx + 1, 0, column)
    })
  },
  methods: {
    triggerTabChange(tabKey) {
      this.$root.$emit('bv::toggle::tab', tabKey)
    },
    toggleCreateNewUnitDrawer() {
      this.$store.commit('globalState/TOGGLE_CREATE_NEW_UNIT_DRAWER')
    },
    onClickCPSelectBtn(url, value) {
      if (value) this.reportingState = value
      const urlArr = this.$route.path.split('/')
      const urls = ['demand', 'reporting-cost', 'reporting-plan', 'control-table']
      if (urls.indexOf(urlArr[urlArr.length - 1]) > -1) {
        urlArr.pop()
        this.$router.push({ path: urlArr.join('/').concat(`/${url}`) })
      } else {
        this.$router.push({ path: this.$route.path.concat(`/${url}`) })
      }
    },
    isUN(data) {
      return isEmpty(data)
    },
    onRangeFromChange(value) {
      const v = `${value.monthIndex} / ${value.year}`
      this.rangeArray[0] = v
      this.selectedMonth = this.rangeArray.join(' - ')
    },
    onRangeToChange(value) {
      const v = `${value.monthIndex} / ${value.year}`
      this.rangeArray[1] = v
      this.selectedMonth = this.rangeArray.join(' - ')
      if (!this.isUN(this.rangeArray[0]) && !this.isUN(this.rangeArray[1])) {
        this.popoverShow = false
      }
    },
    onClose() {
      this.popoverShow = false
    },
    getToday() {
      return `Today ${moment().format('MM/DD/YYYY')}`
    },
    handleChangeViewMode(mode) {
      this.isChartView = mode
    },
    columnChange(columns) {
      const temp = [...this.defaultFields]
      columns.forEach((column, idx) => {
        temp.splice(idx + 1, 0, column)
      })
      this.fields = temp
      this.activeColumns = columns
      this.$store.commit('teamState/REPORTING_COLUMN_UPDATE', columns)
    },
    handleDemandUpdate() {
      this.$store.commit('teamState/UPDATE_DATA')
    }
  }
}
</script>

<style lang="scss">
.popover-body {
  position: absolute;
  left: -576px;
}

.mask {
  background: #000 !important;
}

@import '@core/scss/vue/pages/dashboard-portfolio.scss';
@import "@core/scss/vue/pages/dashboard-project.scss";
</style>
